@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html {
  margin: 0;
  font-family: "Nunito", sans-serif !important;
}

#root {
  height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  #root {
    height: -webkit-fill-available;
  }
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

.content {
  background: linear-gradient(
    220.87deg,
    #f4f3f2 13.54%,
    #f9f5ff 51.56%,
    #f5f5f7 78.13%
  );
}

.Log-in-frame {
  background: #ffffff;
}

.centerIcon {
  justify-content: center !important;
}

.redesignedFlow {
  height: 336px;
  overflow: auto;
}

.redesignedFlow::-webkit-scrollbar {
  -webkit-appearance: none;
}

.redesignedFlow::-webkit-scrollbar:vertical {
  width: 6px;
}

.redesignedFlow::-webkit-scrollbar-button:increment,
.redesignedFlow::-webkit-scrollbar-button {
  display: none;
}

.redesignedFlow::-webkit-scrollbar-thumb {
  background-color: #666666;
  border-radius: 8px;
  border: none;
}

.colorIcon {
  color: rgba(0, 0, 0, 0.54);
}

/* Custom scrollbar styles */
.scrollbar-thin::-webkit-scrollbar {
  width: 0.25rem;
}
.scrollbar-thumb-gray-200::-webkit-scrollbar-thumb {
  background-color: rgba(229, 231, 235, 1);
  border-radius: 0.5rem;
}
.scrollbar-track-gray-100::-webkit-scrollbar-track {
  background-color: rgba(243, 244, 246, 1);
}

.paused {
  animation-play-state: paused;
}
